import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../components/SEO"
import Header from "../components/Header"
import PageNav from "../components/PageNav"

const PageNotFound = (props) => {

  const intl = useIntl()

  return (
    <div className="min-h-screen flex flex-col">
      <Seo title={intl.formatMessage({ id: "pagenotfound.title.seo" })} />
      <Header />

      <div className="flex-grow">
        <div className="sir-section sir-layout-2-3 text-center mb-24">
          <h1 className="title-l mb-8 uppercase">
          <FormattedMessage id="pagenotfound.title" />
          </h1>
          <p className="body-l"><FormattedMessage id="pagenotfound.text" />. <Link to ="/"><span className="link "><FormattedMessage id="pagenotfound.cta" /></span> &rarr;</Link></p>
        </div>
      </div>
      <PageNav />
    </div>
  )
}

export default PageNotFound;